import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" keywords="" />
      <div className={"container"}>
          <div className={"content"}>
              <div className={"title"}>
                <h1>Blog</h1>
              </div>
              {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <div>
              <Link to={node.frontmatter.path} title={node.frontmatter.path}>
                <h2 style={{margin: `0`}}>{node.frontmatter.title}</h2>
              </Link>
              <p style={{margin: `0`, fontSize: `13px`}}>{node.frontmatter.date}</p>
              <p style={{margin: `0`, fontSize: `16px`}}>{node.frontmatter.description}</p>
            </div>
          </div>
        ))}
          </div>
      </div>   
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false }, content: { eq: "blog" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
            path
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
